/** @jsx jsx */

import { graphql } from "gatsby"
import { KennisPage } from "../pages-base/KennisPage"

export default KennisPage("nl")

export const query = graphql`
  query KennisPage {
    datoCmsKennisPage {
      id
      title
      body
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      categoriesBodyNode {
        id
      }
      categoriesBodyNode {
        childMarkdownRemark {
          html
        }
      }
      categoriesTitle
      categories {
        ... on DatoCmsCategory {
          title
          count
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      content {
        ... on DatoCmsBanner {
          ...BannerFragment
        }
        ... on DatoCmsIntro {
          ...IntroFragment
        }
        ... on DatoCmsDoubleImage {
          ...DoubleImageFragment
        }
        ... on DatoCmsTextBlock {
          ...TextBlockFragment
        }
        ... on DatoCmsTextImageBlock {
          ...TextImageBlockFragment
        }
        ... on DatoCmsTextImageBlockLeft {
          ...TextImageBlockLeftFragment
        }
        ... on DatoCmsQuote {
          ...QuoteFragment
        }
        ... on DatoCmsEventsList {
          ...EventsListFragment
        }
        ... on DatoCmsMagazine {
          ...MagazineFragment
        }
        ... on DatoCmsSocial {
          ...SocialFragment
        }
      }
    }
    allDatoCmsKnowledge(filter: { locale: { eq: "nl" } }) {
      edges {
        node {
          originalId
          age
          bodyNode {
            childMarkdownRemark {
              html
            }
          }
          name
          city
          category
        }
      }
    }
  }
`
